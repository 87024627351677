import { v4 as uuidv4 } from "uuid";

import * as formSchema from "./formSchema";
import * as accessControl from "./access_control";
import * as compType from "./compType";
import * as lang from "./lang";

export const getTXOneAccountUrl = () => process.env.REACT_APP_TXONE_ACCOUNT_URL;
export const getSSOClientId = () => process.env.REACT_APP_SSO_CLIENT_ID;
export const getSSORedirectUri = () => process.env.REACT_APP_SSO_REDIRECT_URI;
export const generateUUID = () => uuidv4();
export const getEnv = () => process.env.REACT_APP_ENV;
export const getEnableChatbot = () => process.env.REACT_APP_ENABLE_CHATBOT;
export const getDownloadUrl = () => {
  const domain = window.location.origin;
  let url = "";
  if (getEnv() === "local") {
    url = "http://localhost:8080/api/v1/download";
  } else {
    url = `${domain}/api/v1/download`;
  }
  return url;
};

export * from "./supportTicket";
export * from "./license";
export * from "./common";
export { formSchema, accessControl, compType, lang };
