import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import { useSnackbar } from "notistack";
import _ from "lodash";
import Cookies from "js-cookie";

import { useErrMsg } from "./useErrMsg";

const axiosInstance = axios.create();
axiosRetry(axiosInstance, {
  retries: 2,
  retryCondition(err) {
    const errCode = _.get(err, "response.data.code");
    if (errCode === 4512) {
      return true;
    }
    return false;
  },
});

export const usePureAxios = (options) => {
  const { enqueueSnackbar } = useSnackbar();
  const getErrMsg = useErrMsg();
  const [isLoading, setIsLoading] = useState(false);
  const showError = _.get(options, "showError", true);
  const dataFetcher = useMemo(
    () => async (url, method, requestData) => {
      setIsLoading(true);
      try {
        const headers = {};
        if (method === "post" || method === "delete" || method === "put") {
          headers["X-XSRF-TOKEN"] = Cookies.get("xsrf-token");
        }
        return await axiosInstance({ url, method, data: requestData, headers });
      } catch (err) {
        const errCode = _.get(err, "response.data.code");
        const errMsg = getErrMsg(errCode);
        if (showError) {
          enqueueSnackbar(errMsg, { variant: "error" });
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, showError, setIsLoading, getErrMsg]
  );
  return { isLoading, dataFetcher };
};

export const useAxios = (options) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const getErrMsg = useErrMsg();
  const [isLoading, setIsLoading] = useState(false);
  const showError = _.get(options, "showError", true);
  const handleUnauthErr = _.get(options, "handleUnauthErr", true);
  const dataFetcher = useMemo(
    () => async (url, method, requestData) => {
      setIsLoading(true);
      try {
        const headers = {};
        if (method === "post" || method === "delete" || method === "put") {
          headers["X-XSRF-TOKEN"] = Cookies.get("xsrf-token");
        }

        return await axiosInstance({ url, method, data: requestData, headers });
      } catch (err) {
        const errCode = _.get(err, "response.data.code");
        const errMsg = getErrMsg(errCode);
        if (showError) {
          enqueueSnackbar(errMsg, { variant: "error" });
        }
        if (_.get(err, "response.status") === 401 && handleUnauthErr) {
          navigate("/");
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, showError, setIsLoading, handleUnauthErr, navigate, getErrMsg]
  );
  return { isLoading, dataFetcher };
};

export const useFetch = (url, method, requestData, options) => {
  const [data, setData] = useState(null);
  const { dataFetcher, isLoading } = useAxios(options);
  const apiCaller = useCallback(async () => {
    try {
      const result = await dataFetcher(url, method, requestData);
      setData(result.data);
    } catch (err) {
      // errors are handle in dataFetcher
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, requestData]);

  useEffect(() => {
    apiCaller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, requestData]);

  return { isLoading, data, apiCaller };
};

export const useFetchWithReducer = (dispatch, url, method, requestData, options) => {
  const { dataFetcher } = useAxios(options);

  useEffect(() => {
    (async () => {
      dispatch({ type: "set_loading_status", payload: { status: true } });
      try {
        const result = await dataFetcher(url, method, requestData);
        dispatch({ type: "set_data", payload: { data: result.data, url } });
      } catch (err) {
        // errors are handled in dataFetcher
      } finally {
        dispatch({ type: "set_loading_status", payload: { status: false } });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, requestData]);

  return {};
};
