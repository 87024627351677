import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useErrMsg = () => {
  const { t } = useTranslation();
  return useMemo(
    () => (errCode, extra) => {
      let errMsg = "";
      switch (errCode) {
        case 4500:
          errMsg = t("common.errorMsg.invalidParameter");
          break;
        case 4501:
        case 4502:
        case 4503:
          errMsg = t("common.errorMsg.unauthorized");
          break;
        case 4504:
          errMsg = t("common.errorMsg.sessionExpired");
          break;
        case 4505:
          errMsg = t("common.errorMsg.componentNotFound");
          break;
        case 4506:
          errMsg = t("common.errorMsg.componentFileNotFound");
          break;
        case 4507:
          errMsg = t("common.errorMsg.permissionDenied");
          break;
        case 4508:
          errMsg = t("common.errorMsg.invalidLicenseKey");
          break;
        case 4509:
          errMsg = t("common.errorMsg.trialLicenseNotAccept");
          break;
        case 4510:
          errMsg = t("common.errorMsg.licenseHasExpired");
          break;
        case 4511:
          errMsg = t("common.errorMsg.licenseNotStartYet", extra);
          break;
        case 4512:
          errMsg = t("common.errorMsg.invalidCSRFToken");
          break;
        case 4519:
          errMsg = t("common.errorMsg.reqTimeout");
          break;
        case 4528:
          errMsg = t("common.errorMsg.licenseHasRevoked");
          break;
        case 4726:
          errMsg = t("common.errorMsg.productSNOrMacAddressNotFound");
          break;
        case 4737:
          errMsg = t("common.errorMsg.userAlreadyInvited");
          break;
        case 4999:
          errMsg = t("common.errorMsg.reqLimitExceeded");
          break;
        default:
          errMsg = t("common.errorMsg.unknownError");
          break;
      }
      if (!errCode) {
        // eslint-disable-next-line no-param-reassign
        errCode = 5000;
      }
      return `${errMsg} (${errCode})`;
    },
    [t]
  );
};

export default {};
